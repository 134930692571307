import React from "react";

import App from "./App";
import ReactDOM from "react-dom/client";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
