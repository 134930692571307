import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const IndoorMap = lazy(() => import("./pages"));
const IndoorMapDesktop = lazy(() => import("./pages/desktop"));
const Error = lazy(() => import("./pages/error"));
const ErrorDesktop = lazy(() => import("./pages/desktop/error"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/:airport" element={<IndoorMap />} />
          <Route path="/desktop/:airport/:lang" element={<IndoorMapDesktop />} />
          <Route path="*" element={<Error />} />
          <Route path="/desktop/*" element={<ErrorDesktop />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}



export default App;
